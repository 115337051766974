.auth_page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  form {
    width: 40%;
    margin: auto;
    padding: 4rem 2rem;

    border: 1px solid #ccc;
    border-radius: 1rem;
  }

  .pass_input {
    display: flex;
    flex-direction: column;
    position: relative;

    small {
      cursor: pointer;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@media screen and (max-width: 800px) {
  .auth_page {
    form {
      width: 70%;
    }
  }
}

@media screen and (max-width: 640px) {
  .auth_page {
    form {
      width: 80%;
    }
  }
}

@media screen and (max-width: 480px) {
  .auth_page {
    form {
      padding: 1rem 1rem;
      width: 90%;
    }
  }
}
