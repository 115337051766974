.profile {
  &--container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 80%;
    .profile--tab {
      border-top: 1px solid #ccc;

      button {
        background: transparent;
        border: none;
        padding: 5px 40px;

        &.active {
          font-weight: 500;
          border-bottom: 1px solid #000;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .profile {
    &--container {
      margin-top: 0;
      padding-top: 0;
      width: 100%;

      .info {
        margin: 3rem 0;

        &__container {
          .info__content {
            margin-left: 10px;

            &--title {
              flex-direction: column;
              .editBtn {
                margin-left: 0;
              }
            }
          }
        }

        .info__content--sub {
          flex-direction: column;

          .item {
            padding: 5px 0;
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .profile {
    &--container {
      margin-bottom: 40px;
      width: 90%;
    }
  }
}
