.avatar {
  z-index: 10;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #ccc;

  &.small {
    width: 20px;
    height: 20px;
  }

  &.small-28 {
    width: 28px;
    height: 28px;
  }

  &.small-32 {
    width: 32px;
    height: 32px;
  }

  &.small-40 {
    width: 40px;
    height: 40px;
  }

  &.small-56 {
    width: 56px;
    height: 56px;
  }

  &.medium {
    width: 40px;
    height: 40px;
  }

  &.big {
    width: 100px;
    height: 100px;
  }

  &.very-big {
    width: 150px;
    height: 150px;
  }
}
