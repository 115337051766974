.edit-profile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0008;
  z-index: 200;
  overflow: auto;
  display: flex;
  align-items: center;

  .btn_close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: #ccc;
  }

  form {
    position: relative;
    max-width: 450px;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    margin: auto;
    background-color: white;

    .info_avatar {
      overflow: hidden;
      position: relative;
      margin: auto;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      border: 1px solid #ddd;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }

      span {
        position: absolute;
        bottom: -100%;
        left: 0;
        width: 100%;
        height: 50%;

        text-align: center;
        color: red;
        transition: 0.3s ease-in-out;
        background: #fff5;
      }

      input {
        cursor: pointer;
      }

      #file_up {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
      }
    }

    .info_avatar:hover span {
      bottom: -15%;
    }
  }
}
