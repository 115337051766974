.info {
  // position: relative;
  z-index: 10;

  &__avatar {
    margin: auto;
    border: 1px solid #ccc;
  }
  &__content {
    margin-left: 100px;

    &--title {
      .editBtn {
        margin-left: 40px;
      }
    }

    &--sub {
      .item {
        text-decoration: none;
        color: #000;
        font-size: 16px;
        cursor: pointer;
      }

      .item + .item {
        margin-left: 80px;
      }
    }

    &--detail {
      margin-top: 1rem;
      h6 {
        margin: 0;
      }

      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__details {
    width: 100%;
    &--menu {
      width: 100%;
      margin-top: 60px;
      border-top: 1px solid #ccc;
      li {
        display: flex;
        font-size: 18px;
        align-items: center;

        padding: 10px 20px;
        cursor: pointer;

        span {
          margin-right: 10px;
        }
      }
    }

    &--item {
      &.selected {
        border-top: 1px solid #000;
      }
    }
  }
}
