.user_component {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
  padding: 0 10px;

  &-content {
    display: flex;
    align-items: center;

    text-decoration: none;

    .user_component-info {
      margin-left: 10px;
      p {
        margin: 0;
        color: black;
        font-weight: 500;
        font-size: 18px;
      }
      span {
        color: rgb(128, 127, 127);
      }
    }
  }
}
