.message {
  height: 100vh;
  &--container {
    padding-top: 45px;
    display: flex;
    flex-direction: row;
  }
}

@media screen and (max-width: 640px) {
  .message {
    .message--container {
      padding-top: 0;
      .message--container__left {
        width: 100%;
        border: none;
      }
      .right__mess {
        display: none;
      }
    }
  }
}
