.home {
  display: flex;
  .main {
    width: 80%;
  }
  .suggestion {
    margin-top: 45px;
  }
}

@media screen and (max-width: 640px) {
  .main {
    width: 100% !important;
    padding: 10px 0;
  }

  .home {
    .navbar {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #ccc;

      padding: 5px 0 !important;

      .navbar-brand {
        display: none;
      }

      .search_form {
        display: flex;
        margin: auto;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .main {
    width: 100% !important;
  }
  .home {
    .posts {
      margin-bottom: 40px;
    }
  }
}
