.postcards {
  width: 68%;
  background: white;

  .card__header {
    position: relative;
    &--left {
      .card__name {
        margin-left: 10px;
      }
    }

    &--right {
      span {
        cursor: pointer;

        &:hover {
          filter: blur(0.8px);
        }
      }
    }

    .dropdown-menu {
      .dropdown-item {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          margin-right: 1rem;
        }
      }
    }
  }

  .card__body {
    margin-bottom: 0;
    .card__image {
    }

    .card__content {
      p {
        margin-bottom: 10px;
        padding: 0 0.25rem;
      }
    }

    .card__image {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }

  .card__footer {
    &--action {
      ul {
        margin-bottom: 0;
        margin-left: -2px;
      }
      .action--item {
        margin: 0;
        color: black;
        cursor: pointer;

        &:hover {
          filter: blur(0.8px);
        }
      }

      .action--item + .action--item {
        margin-left: 15px;
      }

      .action--right {
        margin-right: -5px;
      }
    }

    &--comment {
      margin-top: 15px;
    }

    &--info {
      .card__likes {
        cursor: pointer;
      }

      .card__comments {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .postcards {
    width: 100%;
    margin: 10px 0 !important;
  }
}

@media screen and (max-width: 1200px) {
  .postcards {
    width: 90%;
    margin: 10px 0 !important;
  }
}
