body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#theme {
  display: none;
}

#theme:checked ~ .App {
  filter: invert(1);
}

.App {
  width: 100%;
  min-height: 100vh;
  background-color: #f0f2f5;
}

.main {
  max-width: 1200px;
  width: 100%;
  margin: auto;

  padding: 15px 0;

  margin-top: 45px;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0;
}

.ml-1 {
  margin-left: 5px;
}

.ml-2 {
  margin-left: 10px;
}

.ml-3 {
  margin-left: 15px;
}

.mr-2 {
  margin-right: 10px;
}

.mr-3 {
  margin-right: 15px;
}

.pl-4 {
  padding-left: 20px;
}
