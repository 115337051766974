.userPost {
  height: 100%;
  &--container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 4px;

    &__info {
      position: relative;
      background-color: #000;
      cursor: pointer;
      width: 32.3%;
      aspect-ratio: 1 / 1;

      .userPost__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .userPost__info {
        display: none;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        div {
          display: flex;
          align-items: center;
          color: white;
          font-weight: bold;

          p {
            margin-left: 5px;
            font-size: 20px;
          }
        }

        div + div {
          margin-left: 20px;
        }
      }

      &:hover {
        .userPost__img {
          filter: blur(0.8px);
        }

        .userPost__info {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        }
      }
    }
  }
}
