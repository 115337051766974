.create-post {
  width: 68%;
  margin: 20px auto;
  background-color: white;

  &__top {
    padding: 0 4px;
    input {
      margin-left: 10px;
      text-align: left;
      outline: none;
    }
  }

  .line {
    height: 1px;
    background-color: #ccc;
  }

  &__bottom {
    button > span {
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 640px) {
  .create-post {
    margin: 5px auto;
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .create-post {
    margin: 5px auto;
    width: 90%;
  }
}
