.notify {
  &--container {
    margin-top: 45px;
    width: 60%;
    .notify--item {
      width: 100%;
      &__content {
        h6 {
          margin-bottom: 0;
          margin: 0 5px;
        }
      }

      &:hover {
        background-color: #ccc;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .notify {
    .main {
      width: 100%;
    }
    .navbar {
      border-bottom: 1px solid #ccc;
      padding: 10px 0;
      .search_form {
        width: 80%;
        margin: 5px auto;
        display: flex;
      }
    }
  }
}
