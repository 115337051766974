.follows {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0008;
  z-index: 200;
  overflow: auto;
  display: flex;
  align-items: center;

  .btn_close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: #ccc;
  }

  .btn_close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0;

    background-color: #ccc;
  }

  form {
    max-width: 450px;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    margin: auto;
    background-color: white;
    position: relative;
  }

  &_content {
    width: 100%;
    border-radius: 5px;
    margin: auto;
    background-color: white;

    h3 {
      padding: 5px;
      font-size: 20px;
      text-align: center;
      border-bottom: 1px solid #ccc;
    }
  }
}
