.posts {
  height: 100%;
  display: flex;

  &--container {
    width: 100%;
    height: 100%;
    margin-top: 40px;

    .posts--header {
      margin-top: 8px;
      position: relative;
      background-color: white;
      border-bottom: 1px solid #ccc;

      .back--btn {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        display: none;

        span {
          font-size: 30px;
          margin-bottom: 0;
        }
      }

      h5 {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .posts {
    margin-bottom: 50px;
    &--container {
      margin-top: 0;

      .posts--header {
        margin-top: 0;
        position: fixed;
        .back--btn {
          display: flex;
        }
      }

      .postcards {
        margin-top: 50px !important;
      }

      .alert {
        margin-top: 50px;

        p {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .posts {
    &--container {
      .posts--header {
        position: fixed;
        .back--btn {
          display: flex;
        }
      }

      .postcards {
        margin-top: 60px !important;
      }

      .alert {
        margin-top: 50px;

        p {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
