@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(230, 230, 230, 0.5); /* Mờ trang */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
