.commentBox {
  &__container {
    .action__item {
      cursor: pointer;
      font-size: 20px;
    }

    input {
      border: none;
      outline: none;
      margin: 0 10px;

      &:focus {
        border: none;
      }
    }

    button {
      padding: 0;
      border: none;
      outline: none;
    }
  }
}
